import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import { PageHeader, Section, Content } from "../styles/StyledElements"

export default function ResearchDrivers({ data }) {
  const subset = data.allAirtable.nodes.filter(
    node => node.data.Research_Driver !== null
  )

  const rd_ai = subset.filter(node =>
    node.data.Research_Driver.includes("Artificial Intelligence")
  )
  const rd_hci = subset.filter(node =>
    node.data.Research_Driver.includes("Human-Computer Interaction")
  )
  const rd_sp = subset.filter(node =>
    node.data.Research_Driver.includes("Security & Privacy")
  )

  return (
    <Layout>
      <Seo title="Research Drivers" />
      <PageHeader>
        <Content>
          <h1>Research Drivers</h1>
        </Content>
      </PageHeader>
      <Section>
        <Content>
          <p>[Description of the Research Drivers]</p>
          <h2>Artificial Intelligence</h2>
          <ul>
            {rd_ai.map(node => (
              <li key={node.recordId}>
                <Link to={`/trends/${node.recordId}`}>
                  <strong>{node.data.Name}</strong>
                </Link>
              </li>
            ))}
          </ul>
          <h2>Human-Computer Interaction</h2>
          <ul>
            {rd_hci.map(node => (
              <li key={node.recordId}>
                <Link to={`/trends/${node.recordId}`}>
                  <strong>{node.data.Name}</strong>
                </Link>
              </li>
            ))}
          </ul>
          <h2>Security &amp; Privacy</h2>
          <ul>
            {rd_sp.map(node => (
              <li key={node.recordId}>
                <Link to={`/trends/${node.recordId}`}>
                  <strong>{node.data.Name}</strong>
                </Link>
              </li>
            ))}
          </ul>
        </Content>
      </Section>
    </Layout>
  )
}

export const query = graphql`
  {
    allAirtable(
      filter: { table: { eq: "Trends" } }
      sort: { fields: data___Name, order: ASC }
    ) {
      nodes {
        recordId
        data {
          Name
          Summary
          Research_Driver
        }
      }
    }
  }
`
